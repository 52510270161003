.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.command-prompt {
  color: #4af626;
  margin-right: calc(5px + 1vmin);
  float: left;
}

.Typist {
  float: left;
}

.output {
  display: flex;
  align-items: flex-end;
}

body {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

a {
  text-decoration: underline;
  color: #61dafb;
}

svg {
  margin-right: 0.5em;
  height: 25px;
  width: 25px;
}

.terminalScreen {
  width: 75%;
  height: 60vh;
  background-color: rgba(21, 21, 21, 0.7);
  box-sizing: border-box;
  margin: 0 auto;
  padding: 20px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  overflow-y: auto;
}

.terminalButtons {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  border: 1px solid #000;
  position: relative;
  display: inline-block;
  margin-left: 3px;
  margin-right: 3px;
}

.terminalClose {
  margin-left: 10px;
  background-color: #ff3b47;
  border-color: #9d252b;
}

.terminalMinimize {
  background-color: #ffc100;
  border-color: #9d802c;
}

.terminalZoom {
  background-color: #00d742;
  border-color: #049931;
}

.terminalMenu {
  width: 75%;
  box-sizing: border-box;
  height: 1em;
  background-color: #bbb;
  margin: 0 auto;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.terminal {
  width: 80%;
}

@media only screen and (max-width: 1200px) {
  .terminal {
    width: 100%;
  }
}

@media only screen and (max-width: 992px) {
}

@media only screen and (max-width: 768px) {
}

@media only screen and (max-width: 576px) {
}
